import React, { useEffect, useState } from 'react';
import { SubMenu, SubMenuWrapper } from '../../ui';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectFeatures } from '../settings/settingsSlice';
import { useSelector } from 'react-redux';

const Users = () => {
  const { t } = useTranslation();

  const features = useSelector(selectFeatures)
  const [navigationItems, setNavigationItems] = useState([])

  useEffect(() => {
    const items = [
      { label: t('globals.users'), 'value': 'all' },
      { label: t('globals.groups'), 'value': 'groups' },
      features.addTeacherToCourse && { label: t('globals.teachers'), 'value': 'teachers' },
    ].filter(Boolean)
    setNavigationItems(items)

  }, [features, setNavigationItems])

  return (
    <>
      <SubMenuWrapper direction='column' align='flex-start'>
        {!!navigationItems.length && <SubMenu navigationItems={navigationItems} pathnameSplitIndex={2} />}
      </SubMenuWrapper>
      <Outlet />
    </>
  );
};

export default Users;
