import React, { useEffect } from 'react';

import styled from 'styled-components'
import { Typo, SearchSelect } from '../../ui';
import { useTranslation } from 'react-i18next';

const Title = styled(Typo.SmallText)`
  margin-bottom:0.5rem;
`
const UcpPeriodSelector = ({ options, duration, setDuration }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!duration?.label) setDuration({ label: 'oneYear', id: 12, time: 31536000 })
  }, [duration, setDuration])

  return (
    <>
      <Typo.TextTitle>{t('course.ucpPeriodLabel')}</Typo.TextTitle>
      <SearchSelect
        multiple={false}
        setValue={setDuration}
        selectedOptions={duration}
        id='time-period'
        options={options}
        isOptionEqualToValue={(option, value) => option.time === value.time}
        label={t('course.ucpPeriodTitle')}
        optionLabel='label'
        translationLabel='course'
      />
    </>
  );
}

export default UcpPeriodSelector;
