import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { theme, Typo, Icon } from '../../../../ui';
import { List, ListItem, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useNewViewCourseId } from '../../../../hooks/readFromUrls';


const AccordionStyled = styled(Accordion)`
  margin: 0 !important;
  padding-bottom: 0 !important;
  box-shadow: none !important;
  border: none !important;
  background-color: ${({$active})=> $active ? theme.colors.NEUTRAL_NEW_LIGHT : theme.colors.NEUTRAL_0} !important;
  &:before {
    background-color: transparent !important;
  }

  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    background: transparent !important;
    &.Mui-expanded {
      margin: 16px 0 0 !important;
    }
  }

  .MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded{
    min-height:0px !important;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }
`;

const ListStyled = styled(List)`
  padding: 0 0 12px !important;
`

const ListItemStyledActive = styled(ListItem)`
  padding-left: 30px;
  position: relative;
  background-color: ${theme.colors.PRIMARY_LIGHT}  !important;
  
  &:before {
    content: '';
    border-left: 6px solid ${theme.colors.COURSIO_NEW};
    position: absolute;
    width: 6px;
    height: 100%;
    left: 0;
  }
`
const ListItemStyled = styled(ListItem)`
  padding-left: 30px;
  position: relative;
`

const SectionDefault = styled.div`
  min-width: 18px;
  height: 18px;
  margin-right: 10px;
  border-radius: 50%;
  background: ${theme.colors.COURSIO_NEW};
`

const SectionViewed = styled(SectionDefault)`
  background: linear-gradient(0deg, ${theme.colors.COURSIO_NEW} 50%, ${theme.colors.COURSIO_NEW_LIGHT} 50%);
`

const SectionReady = styled(SectionDefault)`
  position: relative;
  background-color: ${theme.colors.COURSIO_NEW};

  &::before {
    width: 6px;
    height: 3px;
    content: '';
    border-bottom: 1px solid #fff;
    border-left: 1px solid #fff;
    position: absolute;
    left: 30%;
    top: 35%;
    transform: rotate(-50deg);
  }
`

const PageViewed = styled.div`
  min-width: 18px;
  height: 18px;
  position: relative;
  margin-right: 10px;

  &::before {
    width: 6px;
    height: 3px;
    content: '';
    border-bottom: 1px solid ${theme.colors.COURSIO_NEW};
    border-left: 1px solid ${theme.colors.COURSIO_NEW};
    position: absolute;
    left: 30%;
    top: 35%;
    transform: rotate(-50deg);
  }
`
const PageUnseen = styled.div`
  width:28px;
`

const TocMenuSection = ({section, activePageId, active}) => {
  const navigate = useNavigate();
  const courseId = useNewViewCourseId();
  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const navigateToPage = (pageId) => {
    navigate(`/view/course/${courseId}/${pageId}`, {pageId});
  }

  return (
    <AccordionStyled 
      key={section.id}
      expanded={expanded}
      onChange={handleChange}
      $active={active}
    >
      <AccordionSummary expandIcon={<Icon.ExpandMore />}>
        {section.progress === 1 ? <SectionReady /> : section.progress > 0 ?  <SectionViewed /> : <SectionDefault />}
        <Typo.Text>{section.name}</Typo.Text>
      </AccordionSummary>
      <AccordionDetails>
        <ListStyled>
          {section.pages.map(page => {
            const Component = page.id?.toString() === activePageId ? ListItemStyledActive : ListItemStyled;
            return(
              <Component 
                key={page.id}
                button
                onClick={() => navigateToPage(page.id)}
              >
                {page.progress === 1 ? <PageViewed /> : <PageUnseen />}
                <Typo.MediumText>{page.name}</Typo.MediumText>
              </Component>
            )
          })}
        </ListStyled>
      </AccordionDetails>
    </AccordionStyled>
  );
};

export default TocMenuSection;
