import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash.clonedeep';
import { Typo, Button, Flex, Toggler, Page } from '../../../ui';
import { saveCourse, selectLayout } from '../courseSlice';
import { CONTENT_SMALL } from '../../../utils/media';
import { getPeriodByTime } from '../../../utils/ucpPeriods';
import UcpPeriodSelector from '../../../components/UcpPeriodSelector';
import { selectCourseDurations } from '../../course/courseSlice';
import { selectStoreHideVAT } from '../../settings/settingsSlice';
import CoursePrice from '../../course/components/CoursePrice';

const EditStoreCourseSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()

  const layoutStructure = useSelector(selectLayout)
  const ucpPeriods = useSelector((state) => selectCourseDurations(state, true))
  const [price, setPrice] = useState(null);
  const [ucpPeriod, setUcpPeriod] = useState({ label: '2 years', time: 63072000 });
  const [isVisibility, setVisibility] = useState(0);

  const { isPackage } = useOutletContext();

  useEffect(() => {
    if (layoutStructure) {
      const tempLayout = cloneDeep(layoutStructure)
      setPrice(tempLayout.price);
      setUcpPeriod(getPeriodByTime(tempLayout.ucpPeriod));
      setVisibility(tempLayout.isPublic);
    } else setPrice({ net: 0, gross: 0, discounted: 0 });
  }, [layoutStructure]);

  const handleVisibility = useCallback(checked => {
    setVisibility(checked)
  }, [])

  const saveSettings = () => {
    const newLayoutData = {
      id: layoutStructure.id,
      ucpPeriod: ucpPeriod.time,
      price: price.net,
      public: isVisibility,
    };
    dispatch(saveCourse({ newData: newLayoutData, isPackage: isPackage }))
  }

  console.log('Edit page', isVisibility, !!isVisibility)


  return (
    <Page size={CONTENT_SMALL} align={'center'} gap='100' >
      <Flex width='100%' gap='30' direction='column' align='flex-start'>
        <Flex direction='column' align='flex-start'>
          <Typo.ContentSmallHeader>{t('globals.access')}</Typo.ContentSmallHeader>
          {ucpPeriod && <UcpPeriodSelector
            duration={ucpPeriod}
            options={ucpPeriods}
            setDuration={setUcpPeriod}
          />}
        </Flex>
        <Flex direction='column' align='flex-start'>
          <Typo.ContentSmallHeader>{t('globals.price')}</Typo.ContentSmallHeader>
          {price && <CoursePrice price={price} setPrice={setPrice} />}
        </Flex>
        <Flex direction='column' align='flex-start' minWidth='200px'>
          <Typo.ContentSmallHeader >{t('globals.visibility')}</Typo.ContentSmallHeader>
          <Toggler leftText={t('globals.private')} rightText={t('globals.public')} onClick={handleVisibility} checked={!!isVisibility} />
        </Flex>
        <Flex justify='flex-end' width='100%' gap='30'>
          <Button icon='Save' onClick={saveSettings} >{t('globals.save')}</Button>
        </Flex>

      </Flex>
    </Page >
  );
};

export default EditStoreCourseSettings;
