import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/en';
import 'dayjs/locale/fi';
import 'dayjs/locale/nb';
import 'dayjs/locale/pl';
import 'dayjs/locale/sv';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, Flex, ListTable, Modal, SubPage, Typo, PillTag } from '../../../ui';
import debounce from '../../../helpers/debounce';
import { useNavigate, useParams } from 'react-router-dom';
import { changeAccessToCourse, fetchCountByCourseId, selectCourse } from '../courseSlice';
import { setInviteModalIsOpen } from '../../invites/invitesSlice';
import UserModal from '../../users/components/UserModal';
import { fetchUserByCourseId, removeUserFromCourse, selectCourseUsers, selectStatus } from "../../users/usersSlice";
import { selectSendStatus } from "../../invites/invitesSlice";
import { STATUS_LOADING } from '../../../utils/constants';
import Loading from '../../../ui/Loading';
import ConfirmDelete from '../../../ui/Modal/ConfirmDelete';
import { useTranslation } from 'react-i18next';
import { selectLocalization } from '../../auth/authSlice';
import { selectTaskUsers } from '../../tasks/tasksSlice';
import TasksByUser from '../../tasks/components/TasksByUser';
import { selectFeatures } from '../../settings/settingsSlice';
import PageControls from '../../../ui/Page/PageControls';
import PageHeader from '../../../ui/Page/PageHeader';

const CourseUsers = ({ modal }) => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const locale = useSelector(selectLocalization)
  const course = useSelector(selectCourse);
  const users = useSelector(selectCourseUsers);
  const taskUsers = useSelector(selectTaskUsers);
  const loadStatus = useSelector(selectStatus);
  const features = useSelector(selectFeatures);

  const sendInvitationStatus = useSelector(selectSendStatus);
  // const usersAmount = useSelector(selectUsersAmount);
  // const [accessLevelToEdit, setAccessLevelToEdit] = useState(null);
  // const userToEdit = useSelector((state) => selectCourseUserById(state, accessLevelToEdit?.userId));
  const [searchQuery, setSearchQuery] = useState('');
  const [userToRemove, setUserToRemove] = useState(null);
  const [endDate, setEndDate] = useState(dayjs());

  const userTasks = useMemo(() => taskUsers.find((obj) => +obj.id === +userId), [userId, taskUsers])

  const [userToEditExpiration, setUserToEditExpiration] = useState(null);

  useEffect(() => {
    !!locale && dayjs.locale(locale)
  }, [locale])

  useEffect(() => {
    const user = userToEditExpiration;
    const date = user?.expires === '0000-00-00' ? dayjs() : dayjs(user?.expires)
    setEndDate(date)

  }, [userToEditExpiration])

  const openUser = useCallback((obj) => {
    navigate(`${obj.id}`)
  }, [navigate]);

  const openUserTasks = useCallback((user) => {
    navigate(`${user.id}/tasks`)
  }, [navigate]);

  const onCloseUserTasks = () => {
    navigate(`/courses/${course.id}/users`)
  }

  const removeFromCourse = useCallback(() => {
    dispatch(removeUserFromCourse({ courseId: course.id, userId: userToRemove.id })).then(() => setUserToRemove(null))
  }, [dispatch, course, userToRemove])

  const isNeverExpire = (date) => date === '2099-12-31' || date === '0000-00-00'

  const saveAccessPeriodEndDate = () => {
    dispatch(changeAccessToCourse({ expires: endDate.toISOString().split('T')[0], userId: userToEditExpiration.id, id: course.id }))
      .then(() => {
        dispatch(fetchUserByCourseId({ courseId: course.id, token: searchQuery }))
        setUserToEditExpiration(null)
      })
  }

  const tableInfo = [
    { label: t('globals.name'), data: (user) => <><Typo.Text>{user.name}</Typo.Text><Typo.Info fontWeight='300' >{user.id}</Typo.Info></> },
    !!features.newTaskView && {
      label: t('globals.tasks'), data: (user) => {
        const userTasks = taskUsers.find((obj) => obj.id === user.id)
        if (userTasks) {
          let resultsUncorrected = 0
          let wrongResults = 0
          Object.values(userTasks?.tasks).map((task) => {
            task.hasBeenApproved === 99 && resultsUncorrected++
            task.hasBeenApproved === 0 && wrongResults++
          })
          const iconLabel = resultsUncorrected > 0 ? 'awaits feedback' : wrongResults > 0 ? ' wrong' : 'All correct'
          return <Flex width='100%' justify='center'>
            <PillTag
              onClick={
                (e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  openUserTasks(user)
                }
              }
              label={`${!!resultsUncorrected > 0 ? resultsUncorrected : !!wrongResults > 0 ? wrongResults : ''} ${iconLabel}`}
            />
          </Flex>
        }
        else {
          return <Flex width='100%' justify='center'><Typo.MediumText>-</Typo.MediumText></Flex>
        }
      }
    },
    { label: t('course.accessUntil'), data: (user) => isNeverExpire(user.expires) ? t('globals.forever') : user.expires },
    // { label: 'Is Manager' },
    // { label: t('globals.tasks') },
    // { label: t('globals.quizes') },
    // { label: 'Pages viewed' },
    // { label: 'Groups' },
    // { label: 'Progress' },
    {
      label: '', menuOptions: (user) => {
        const userTasks = taskUsers.find((obj) => obj.id === user.id)
        const arr = [
          { label: t('course.userDetails'), onClick: openUser },
          !!userTasks && !!features.newTaskView && { label: t('course.openUserTasks'), onClick: openUserTasks },
          { label: t('course.editAccessExpire'), onClick: setUserToEditExpiration },
          { label: t('course.removeUser'), onClick: setUserToRemove, variant: 'action' },
        ].filter(Boolean)
        return arr
      }
    },
  ].filter(Boolean);

  useEffect(() => {
    course.id && sendInvitationStatus !== STATUS_LOADING && dispatch(fetchUserByCourseId({ courseId: course.id, token: searchQuery }));
  }, [searchQuery, course.id, dispatch, sendInvitationStatus]);

  useEffect(() => {
    course.id && dispatch(fetchCountByCourseId({ courseId: course.id }));
  }, [course.id, dispatch]);

  const handleSearch = debounce((value) => {
    setSearchQuery(value);
  }, [setSearchQuery]);

  const clearSearch = useCallback(() => {
    setSearchQuery('');
  }, [setSearchQuery]);

  const openInviteDialog = () => {
    dispatch(setInviteModalIsOpen({ course }))
  }

  return (
    <SubPage staticElements={
      <PageHeader >
        <PageControls
          onClearSearch={clearSearch}
          onSearch={handleSearch}
          placeholder={t('course.searchUser')}
          disableClear={!searchQuery}
          openModelButton={
            <Button icon='Invite' onClick={openInviteDialog}>{t('invites.inviteUser')}</Button>
          }
        />
      </PageHeader>
    }
    >
      {loadStatus === STATUS_LOADING ? <Loading /> :
        <ListTable
          tableInfo={tableInfo}
          data={users}
          tableName={'courseUsers'}
          onRowClick={openUser}
        />}

      <UserModal open={!!userId && !modal} userId={userId} />
      {!!features.newTaskView && <TasksByUser open={!!modal} userTasks={userTasks} handleClose={onCloseUserTasks} courseId={course.id} />}

      <Modal
        titleHasLine
        open={!!userToEditExpiration}
        onClose={() => setUserToEditExpiration(null)}
        showActions={true}
        onAccept={saveAccessPeriodEndDate}
        onCancel={() => setUserToEditExpiration(null)}
        titleChildren={
          <Flex direction='column' align='flex-start' >
            <Typo.ContentSmallHeader>{t('course.editAccessExpire')}</Typo.ContentSmallHeader>
            <Typo.Text>{userToEditExpiration?.name}</Typo.Text>
          </Flex>
        }
      >
        {userToEditExpiration?.expires === '0000-00-00' ?
          <Typo.Text>{t('course.cantEditForever')}</Typo.Text>
          : <Flex direction='column' gap='10' align='flex-start'>
            <Typo.Text>{t('course.editExpireDate')}</Typo.Text>
            <Flex>
              {locale && <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                <DatePicker
                  format="LL"
                  label={t('globals.endDate')}
                  value={dayjs(endDate)}
                  onChange={(newValue) => {
                    const dateObj = new Date(newValue);
                    const dateFormat = new Date(dateObj.getTime() - dateObj.getTimezoneOffset() * 60 * 1000);
                    setEndDate(dateFormat)
                  }}
                />
              </LocalizationProvider>}
            </Flex>
          </Flex>
        }
      </Modal>
      <ConfirmDelete
        onClose={setUserToRemove}
        onAcceptDelete={removeFromCourse}
        open={!!userToRemove}
        title={t('course.confirmRemoval')}
        description={t('course.confirmRemovalDescription', { userToRemove: userToRemove?.name, course: course?.name })}
      />
    </SubPage >
  );
};

export default CourseUsers;
