import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation, Keyboard } from 'swiper';
import "swiper/swiper-bundle.min.css";
import { getDarkerThemeColor, getLighterThemeColor, getUserThemeContrastColor } from '../theme/colors';
import theme from '../theme';

const CarouselStyled = styled(Swiper)`
  .swiper-wrapper {
    margin-bottom: 50px;
  }

  .swiper-pagination-bullet {
    width: 18px;
    height: 18px;
    transition: all 0.25s cubic-bezier(1, 0.06, 0.58, 1.2);
    opacity: ${(props) => props.$opacity};
    background: ${(props) => props.$unactiveColor};
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: ${(props) => props.$activeColor};
  }
  .swiper-button-prev,
  .swiper-button-next,
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    color: white;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0.5;
  }

`;

const Carousel = ({ children, userThemeColor, bgColor, ...props }) => { 

  return (
    <CarouselStyled
      $activeColor={!!bgColor && userThemeColor !== bgColor ? getDarkerThemeColor(userThemeColor) : getUserThemeContrastColor(userThemeColor)}
      $unactiveColor={!!bgColor && userThemeColor === bgColor ? getLighterThemeColor(userThemeColor) : theme.colors.NEUTRAL_60}
      $opacity={0.5}

      modules={[Pagination, Autoplay, Navigation, Keyboard]}
      pagination={{ clickable: true }}
      keyboard={{ enabled: true }}
      navigation={true}
      slidesPerView={1}
      {...props}
    >
      {/* Wrap every child in a swiperslide */}
      {children.map((child, i) => <SwiperSlide key={i}>{child}</SwiperSlide>)}
    </CarouselStyled>
  );
}

export default Carousel;
