import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Dialog, DialogActions, DialogContent, Tab, Tabs, } from '@mui/material';
import { Button, Icon } from '..';
import Typo from '../Typo';
import { breakpoints, media } from '../../utils/media';
import { useTranslation } from 'react-i18next';
import Buttons from '../Buttons';

const DialogWrapper = styled(Dialog)`
  // &.MuiModal-root.MuiDialog-root{
  //   z-index:1316;
  // }
  
  .MuiDialog-paper {
    overflow: ${props => props.overflow ?? 'auto'};
    padding: ${({ compact, noBottomMargin }) => 
      compact === 'true' ? '0' : noBottomMargin === true ? '20px 20px 0' : '20px'
    };
    min-width: 400px;
    min-height: 300px;
  }

  .MuiDialogContent-root {
    padding: ${({ compact }) => compact === 'true' ? '0' : '20px 24px'};
    ${media.lessThan('phablet')} {
      padding: 0;
    }
  }
 
`;

const ModalTitle = styled.div`
  padding: 0 24px;
  padding-bottom:${({ $titleHasLine }) => $titleHasLine ? '1rem' : '0'};
  border-bottom: ${(props) => props.$titleHasLine ? '1px' : '0'} solid ${(props) => props.theme.colors.NEUTRAL_20};

  ${media.lessThan('phablet')} {
    padding: 0;
    padding-bottom:${({ $titleHasLine }) => $titleHasLine ? '1rem' : '0'};
  }
`

const ModalActions = styled.div`
  padding-top: 16px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  button:not(:first-of-type) {
    margin-left: 8px;
  }

  ${media.lessThan('tablet')} {
    padding: 1rem;
  }
`

const Close = styled(Icon.Close)`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.NEUTRAL_20};
  transition: all .15s cubic-bezier(1, 0.06, 0.58, 1.2);

  &:hover {
    color: ${(props) => props.theme.colors.NEUTRAL_60};
  }
`;

const TabWrapper = styled.div`
  margin:1rem 0;
  ${media.lessThan('phablet')} {
    margin: 0;
  }
  .MuiTabs-indicator{
    background-color:${(props) => props.theme.colors.NEUTRAL_PRIMARY} !important;
  }
`
const TabStyled = styled(Tab)`
  font-family:${(props) => props.theme.fonts.HEADING_FONT}, sans-serif !important;
  font-weight: 600 !important;
  font-size: ${(props) => props.theme.fontSizes.medium} !important;
  &.Mui-selected{
    color:${(props) => props.theme.colors.NEUTRAL_PRIMARY} !important;
  }

`

const Modal = ({
  open,
  onClose,
  showActions,
  onAccept,
  acceptLabel,
  acceptIsDisabled,
  title,
  titleHasLine,
  titleChildren,
  children,
  width,
  compact,
  compactOnMobile,
  handleTabChange,
  navigationItems,
  selectedTab,
  variant,
  dialogActionsChildren,
  minWidth,
  noBottomMargin,
  id,
  maxWidth,
  ...props
}) => {

  const showTitle = title || titleChildren
  const fullScreen = window.innerWidth <= breakpoints.phablet;

  const { t } = useTranslation();

  const isCompact = useMemo(() => {
    if (compact || (compactOnMobile && fullScreen))
      return 'true'

  }, [fullScreen, compactOnMobile, compact])


  return (
    <DialogWrapper
      open={!!open}
      compact={isCompact}
      onClose={onClose}
      scroll={'paper'}
      fullScreen={fullScreen}
      noBottomMargin={noBottomMargin}
      maxWidth={maxWidth || 'md'}
      sx={{ '& .MuiDialog-paper': { width: width, minWidth: minWidth, maxWidth:maxWidth  } }}
      {...props}
    >
     {onClose && <Close onClick={onClose} />}

      { showTitle &&
        <ModalTitle $titleHasLine={titleHasLine} id="scroll-dialog-title">
          {title ? <Typo.ContentSmallHeader $textAlign='left' >{title}</Typo.ContentSmallHeader> : titleChildren}
          {!!navigationItems?.length &&
            <TabWrapper>
              <Tabs
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons={'auto'}
                allowScrollButtonsMobile
                value={selectedTab}
              >
                {navigationItems.map((item, index) => {
                  return <TabStyled
                    key={index}
                    label={item.label}
                    aria-label={item.value} />
                })}
              </Tabs>
            </TabWrapper>
          }
        </ModalTitle>
      }
      <DialogContent >{children}</DialogContent>
      {showActions && <ModalActions>
        {!!onClose && <Buttons.Cancel onClick={onClose} />}
        {!!onAccept && <Button onClick={onAccept} disabled={acceptIsDisabled} $variant={variant}>{acceptLabel || t('globals.save')}</Button>}
      </ModalActions>}
      {dialogActionsChildren && <DialogActions>
        {dialogActionsChildren}
      </DialogActions>}
    </DialogWrapper>
  );
};

export default Modal;
