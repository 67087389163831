import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { CONTENT_STANDARD, CONTENT_FULL, media } from '../../utils/media'

const SubPageWrapper = styled.div`
  height: ${({ position }) => `calc(100vh - ${position}px)`}; 
  overflow:hidden;
`

const Header = styled.div` 
  margin: auto;
  max-width: ${({ size }) => size !== CONTENT_FULL ? `${size}px` : size};
  padding: 0;
  ${media.lessThan('laptop')} {
    padding: 0 1.5rem 0;
  }
`

const Scrollable = styled.div` 
  overflow-y: scroll;
  height:100%;
`

const PageContent = styled.div`
  display:flex;
  flex-direction: column;
  margin: auto;
  padding: 1rem 0 2rem;
  max-width: ${({ size }) => size !== CONTENT_FULL ? `${size}px` : size};
  gap: ${(props) => props.gap || 0}px;
  align-items: ${({ align }) => align};

  ${media.lessThan('laptop')} {
    padding: 1rem 1.5rem 2rem 1.5rem;
  }
`

const SubPage = ({
  children,
  staticElements,
  align,
  gap,
  size
}) => {

  const [position, setPosition] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    setPosition(ref?.current?.getBoundingClientRect().y)
  }, [ref?.current?.getBoundingClientRect()])

  return (
    <>
      { staticElements && <Header size={size || CONTENT_STANDARD} >
        {staticElements}
      </Header>}
      <SubPageWrapper ref={ref} position={position} >
        <Scrollable>
          <PageContent align={align || 'center'} gap={gap} size={size || CONTENT_STANDARD}>
            {children}
          </PageContent>
        </Scrollable>
      </SubPageWrapper>
    </>
  );

};

export default SubPage;
