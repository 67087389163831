import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SubMenu, SubMenuWrapper } from '../../ui';
import { useSelector } from 'react-redux';
import { selectFeatures } from '../settings/settingsSlice';

const Exports = () => {

  const { t } = useTranslation();

  const features = useSelector(selectFeatures)
  const [navigationItems, setNavigationItems] = useState([])


  useEffect(() => {
    const items = [
      { label: t('export.courseExport'), 'value': 'course' },
      { label: t('export.userExport'), 'value': 'user' },
      { label: t('export.groupExport'), 'value': 'group' },
      features.salesExport && { label: t('export.sales'), 'value': 'sales' }
    ].filter(Boolean)
    setNavigationItems(items)

  }, [features, setNavigationItems])

  return (
    <>
      <SubMenuWrapper>
        {!!navigationItems.length && <SubMenu navigationItems={navigationItems} pathnameSplitIndex={2} />}
      </SubMenuWrapper>
      <Outlet />
    </>
  );
};

export default Exports;
