import React, { useEffect, useState } from 'react';
import styled from 'styled-components'

import { useTranslation } from 'react-i18next';
import { Button, IconAsButton, theme, Typo } from '../../ui';

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  z-index: 9999;
  justify-content: center;
  width: 100%;
  padding-top:5px;
  align-items:center;
`
const Content = styled.div`
  background-color: ${theme.colors.SECONDARY_LIGHT};
  display: flex;
  align-items: center;
  padding: 5px 10px;
  gap:5px;
`

const WarningMessages = ({ show }) => {
  const { t } = useTranslation();
  const [hideMessage, setHideMessage] = useState(false)

  //change value so it is unique every time we set a new warning
  const currentProperty = '2024Aug13-solved';

  useEffect(() => {
    const dontShowWarning = JSON.parse(localStorage.getItem('dontShowWarning'));
    setHideMessage(dontShowWarning && dontShowWarning[currentProperty])

  }, [setHideMessage])

  const onCloseWarning = () => {
    localStorage.setItem('dontShowWarning', JSON.stringify({ [currentProperty]: true }));
    setHideMessage(true)
  }

  return (!hideMessage && show ?
    <Wrapper>
      <Content>
        <Typo.SmallText fontWeight={600} $themeColor={theme.colors.SECONDARY_LIGHT}>
          {t('warning.banner')}
        </Typo.SmallText>
        <IconAsButton size='small' iconName='Close' clickHandle={onCloseWarning} />
      </Content>
    </Wrapper> : null
  );
};

export default WarningMessages;
