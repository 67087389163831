import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import isEmpty from 'lodash.isempty';
import { Loading, Button, Flex, theme } from '../../ui';
import { fetchCourseById, selectCourse, selectCourseStatus } from '../course/courseSlice';
import { useNewViewCourseId, useNewViewPageId } from '../../hooks/readFromUrls';
import { STATUS_LOADING, coursePageNavigation, drawerWidth } from '../../utils/constants';
import { media } from '../../utils/media';
import TocMenu from './components/TocMenu';
import CoursePage from './components/CoursePage';

const NavButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  height: ${coursePageNavigation}px;
  width: calc(100% - ${drawerWidth}px); //we need calc because fixed position anchored to the viewport

  ${media.lessThan('tablet')} {
    width:100%;
  }
`

const NavButton = styled(Button)`
  border-radius: 0;
  width: 100%;
  display: flex;
  justify-content: ${({ $align }) => $align};
`

const ContentWrapper = styled(Flex)`
  margin-left:${drawerWidth}px;
  position:relative;
  height: calc(100vh - ${theme.headerSize});
  ${media.lessThan('tablet')} {
    margin-left:0;
    width: 100%;
  }
`

const ViewCourse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const courseId = useNewViewCourseId();
  const urlPageId = useNewViewPageId();
  const course = useSelector(selectCourse);
  const courseStatus = useSelector(selectCourseStatus);
  const [next, setNext] = useState(null);
  const [prev, setPrev] = useState(null);

  useEffect(() => {
    dispatch(fetchCourseById({ courseId })).then((courseData) => {
      if (urlPageId) {
        navigate(`/view/course/${courseId}/${urlPageId}`, {urlPageId});
      } else {
        const { lastViewedPageId, toc } = courseData.payload;
        const activePage = lastViewedPageId ? lastViewedPageId : toc[0].pages[0].id;

        navigate(`/view/course/${courseId}/${activePage}`, {activePage});
      }
    })
  }, []);

  const getNavForPageId = useMemo(() => {
    if (!isEmpty(course)) {
      const withoutQuiz = course.toc ? course.toc.filter(section => section.type !== 'quiz') : [];
      const isQuizEnabled = course.settings.quiz?.enabled;
      const toc = isQuizEnabled ? course.toc : withoutQuiz;

      let activePageIndex;
      const activeSectionIndex = toc.findIndex(section => {
        activePageIndex = section.pages.findIndex(page => page.id === Number(urlPageId));
        return activePageIndex !== -1;
      });

      if (activeSectionIndex !== -1) 
        return {toc, activeSectionIndex, activePageIndex}
      
    } else return null
  }, [course, urlPageId])

  const getPrev = useCallback(() => {
    if(!getNavForPageId) return null
    const {toc, activeSectionIndex, activePageIndex} = getNavForPageId;

    let foundSection = toc[activeSectionIndex];
    let foundPage;

    if (activePageIndex === 0) {
      if (activeSectionIndex > 0) {
        foundSection = toc[activeSectionIndex - 1];
        foundPage = foundSection.pages[foundSection.pages.length - 1];
      } else {
        return null; // No previous page available
      }
    } else {
      foundPage = foundSection.pages[activePageIndex - 1];
    }

    return foundPage
  }, [getNavForPageId])

  const getNext = useCallback(() => {
    
    if(!getNavForPageId) return null

    const {toc, activeSectionIndex, activePageIndex} = getNavForPageId;
    
    let foundSection = toc[activeSectionIndex];
    let foundPage;

    if (activePageIndex === foundSection.pages.length - 1) {
      if (activeSectionIndex < toc.length - 1) {
        foundSection = toc[activeSectionIndex + 1];
        foundPage = foundSection.pages[0];
      } else {
        return null; // No next page available
      }
    } else {
      foundPage = foundSection.pages[activePageIndex + 1];
    }

    return foundPage
  }, [getNavForPageId])

  useEffect(() => {
    setNext(getNext())
    setPrev(getPrev())
  }, [urlPageId, course])

  const goToPage = (pageId) => {
    navigate(`/view/course/${courseId}/${pageId}`, {pageId});
  }

  const RenderNavButtons = memo(({ prev, next }) => {
    const prevName = prev?.name ||  '';
    const nextName = next?.name ||  '';

    return (
      <NavButtonWrapper>
        <NavButton disabled={!prev} onClick={() => goToPage(prev?.id)} $align={'left'}>{prevName}</NavButton>
        <NavButton disabled={!next} onClick={() => goToPage(next?.id)} $align={'right'}>{nextName}</NavButton>
      </NavButtonWrapper>
    )
  });

  return (
    courseStatus === STATUS_LOADING ? <Loading /> :
      <>
        <TocMenu />
        <ContentWrapper direction='column' >
          <CoursePage pageId={urlPageId} />
          <RenderNavButtons prev={prev} next={next}/>
        </ContentWrapper>
      </>
  );
};

export default ViewCourse;
