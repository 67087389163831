import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {Button, Figure, Flex, Loading, Modal, SearchSelect, SubPage, Textinput} from '../../../ui';
import { selectSettings, updateSettings } from '../../settings/settingsSlice';
import styled from 'styled-components';
import ImageUploader from '../../media/uploaders/ImageUploader';
import { LANGUAGES } from '../../../utils/constants'
import { media } from '../../../utils/media';
import Buttons from '../../../ui/Buttons';

const ButtonWrapper = styled.div`
  margin-top:1rem;
  display: flex;
  justify-content: end;
  align-self: end;
  
  button {
    margin-left: 0.5rem;
  }
`
const AvatarWrapper = styled.div`
  max-width: 180px;
  width: 40%; 
  ${media.lessThan('phablet')} {
    margin: auto;
  }
`
const ProfileInfo = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const settings = useSelector(selectSettings)
  const personalInfoRef = useRef({});
  const [currentLanguage, setCurrentLanguage] = useState();
  const [editedLanguage, setEditedLanguage] = useState({ value: 'en_US', label: 'English' });
  const [confirmModal, setConfirmModal] = useState(false);

  useEffect(() => {
    // COULD BE REMOVED IF SETTINGS HAS FULL LANGUAGE OBJECT => {label: 'English', value: 'en_US'}
    if (settings.fetchStatus === 'succeeded') {
      const language = LANGUAGES.find(lang => lang.value === settings.language)
      setCurrentLanguage(language);
      setEditedLanguage(language);
    }
  }, [settings]);

  const saveImage = useCallback(image => {
    if (image) {
      const settingsData = {
        avatarFileId: image.id,
        avatarUrl: image.url,
      };

      dispatch(updateSettings({ settingsData }));
    }
  }, [dispatch]);

  const handleConfirmModal = useCallback(() => {
    setConfirmModal(!confirmModal);
  }, [setConfirmModal, confirmModal]);

  const savePersonalInfo = useCallback(() => {
    const { displayName, firstName, lastName, email, passConfirm } = personalInfoRef.current;
    let settingsData = {
      displayName: displayName.value,
      firstName: firstName.value,
      lastName: lastName.value,
      language: editedLanguage.value
    };

    if (settings.email !== email.value) {
      if (passConfirm) {
        settingsData = {
          ...settingsData,
          email: email.value,
          currentPassword: passConfirm.value
        }
      } else {
        return handleConfirmModal();
      }
    }

    if (confirmModal) {
      setConfirmModal(false);
    }

    dispatch(updateSettings({ settingsData })).then(
      i18n.changeLanguage(settingsData.language)
    )
  }, [dispatch, handleConfirmModal, settings, confirmModal, editedLanguage, i18n])

  const cancelChanges = useCallback(() => {
    personalInfoRef.current['displayName'].value = settings.displayName;
    personalInfoRef.current['firstName'].value = settings.firstName;
    personalInfoRef.current['lastName'].value = settings.lastName;
    personalInfoRef.current['email'].value = settings.email;
    setEditedLanguage(currentLanguage)

  }, [settings, currentLanguage]);

  return (
    settings.fetchStatus === 'succeeded' ?
      <SubPage>
        <Flex align='flex-start' gap={30} width='100%' justify='center' breakPoint={{ size: 'tablet', value: 'flex-direction: column;' }}>
          <AvatarWrapper >
            <ImageUploader
              saveByDelete={true}
              type={'single'}
              id={'profileInfo'}
              saveAction={saveImage}
              imageToPreview={settings.avatarUrl}
            >
              {settings.avatarUrl && <Figure.Circle><img alt={'imageToPreview'} src={settings.avatarUrl} loading='lazy' /></Figure.Circle>}
            </ImageUploader>
          </AvatarWrapper>
          <Flex direction='column' align='flex-start' width='35%' breakPoint={{ size: 'tablet', value: 'width: 100%;' }}>
            <Textinput
              innerRef={el => personalInfoRef.current['displayName'] = el}
              label={t('profile.displayName')}
              defaultValue={settings.displayName}
            />
            <Textinput
              innerRef={el => personalInfoRef.current['firstName'] = el}
              label={t('profile.firstName')}
              defaultValue={settings.firstName}
            />
            <Textinput
              innerRef={el => personalInfoRef.current['lastName'] = el}
              label={t('profile.lastName')}
              defaultValue={settings.lastName}
            />
            <Textinput
              innerRef={el => personalInfoRef.current['email'] = el}
              label={t('globals.email')}
              defaultValue={settings.email}
            />
            <SearchSelect
              setValue={setEditedLanguage}
              selectedOptions={editedLanguage}
              id='language'
              options={LANGUAGES}
              label={t('profile.language')}
              optionLabel='label'
            />
            <ButtonWrapper>
              <Buttons.Cancel onClick={cancelChanges} />
              <Button onClick={savePersonalInfo}>{t('globals.save')}</Button>
            </ButtonWrapper>
          </Flex>
        </Flex>
        <Modal
          open={confirmModal}
          showActions
          onAccept={savePersonalInfo}
          onClose={handleConfirmModal}
          acceptLabel={t('globals.confirmSave')}
          title={t('settings.emailPassConfirm')}
        >
          <Textinput
            innerRef={el => personalInfoRef.current['passConfirm'] = el}
            label={t('profile.currentPassword')}
          />
        </Modal>
      </SubPage>
      :
      <Loading />
  );
};

export default ProfileInfo;
