import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { drawerWidth } from '../../../../utils/constants';
import { theme, Typo } from '../../../../ui';
import { selectCourse, selectCourseToc } from '../../../course/courseSlice';
import { useNewViewPageId } from '../../../../hooks/readFromUrls';
import isEmpty from 'lodash.isempty';
import { media } from '../../../../utils/media';
import TocMenuSection from './TocMenuSection';

const Wrapper = styled.div`
  width: ${drawerWidth}px;
  background-color: white;
  position: fixed;
  top: ${theme.headerSize};
  left: 0;
  height: calc(100vh - ${theme.headerSize});
  overflow: scroll;
  border-right: 1px solid ${theme.colors.NEUTRAL_20};

  ${media.lessThan('tablet')} {
    display:none;
  }
`
const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: contain;
`

const HeadWrapper = styled.div`
  background: ${theme.colors.NEUTRAL_NEW};
  margin: 0;
  max-width: 600px;
`
const CourseName = styled.div`
  min-height: 88px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    padding: 5px 0 5px 15px;
  }
`

const Diploma = styled.div`
  padding:20px;
  background-color: ${props=>props.theme.colors.MARKER_SHADE}
`

const TocMenu = () => {
  const course = useSelector(selectCourse);
  const toc = useSelector(selectCourseToc);
  const activePageId = useNewViewPageId();
  const [active, setActive] = useState([]);
  const [hasDiploma, setHasDiploma] = useState(false);

  useEffect(() => {
    if (!isEmpty(toc) && activePageId !== undefined) {
      const sectionIndex = toc.findIndex(section =>
        section.pages.findIndex(page => page.id.toString() === activePageId) !== -1
      );
      if (sectionIndex !== -1) {
        const newActive = Array(toc.length).fill(false);
        newActive[sectionIndex] = true;
        setActive(newActive);
      }
    }
  }, [toc, activePageId]);

  useEffect(()=>{
    if(course.settings){
      const {diploma, quiz} =  course?.settings
      setHasDiploma(!!diploma?.fileId || quiz?.diplomaByQuizProgress)
    }
    
  }, [course.settings])

  return (
    <Wrapper>
      {course && 
        <HeadWrapper>
          {course.coverImage?.url && <StyledImage src={course.coverImage?.url} alt="Course" />}
          <CourseName>
            <Typo.MediumText fontWeight='600' $textAlign='left'>{course.name}</Typo.MediumText>
            <Typo.SmallText fontWeight='300' $textAlign='left'>By {course.ownerName}</Typo.SmallText>
          </CourseName>
        </HeadWrapper>
      }

      <div>
        {toc?.map((section, i) =>
          <TocMenuSection section={section} activePageId={activePageId} active={!!active[i]}/>
        )}

        { hasDiploma && <Diploma>
          <Typo.Text>This course gives a diploma after completion</Typo.Text> 
        </Diploma> }
      </div>
    </Wrapper>
  );
};

export default TocMenu;
