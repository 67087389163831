import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Header, Avatar } from '../../ui';
import theme from '../../ui/theme';
import {
  setMenuSliderToggle,
  setLoginModal,
  selectSubModule
} from './globalSlice';
import {
  selectStoreName,
  selectUserTheme,
  selectOwnerName,
} from '../webshop/globalSlice';
import { selectCartItemsCount } from '../webshop/cartSlice';
import { selectAuthState } from '../auth/authSlice';
import { useModule } from '../../hooks/readFromUrls';
import { MODULES_LIST } from '../../utils/constants';

//Menu button
const ProfileAvatar = styled.div`
  z-index:1315;
  position: fixed;
  left: 11px;
  top: 11px;
  cursor: pointer; 
  margin-right:0.5rem;
`

const HeaderContainer = ({ noHeader }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const storeName = useSelector(selectStoreName)
  const userTheme = useSelector(selectUserTheme)
  const cartItemsCount = useSelector(selectCartItemsCount)
  const authState = useSelector(selectAuthState);
  const ownerName = useSelector(selectOwnerName)
  const module = useModule();
  const subModule = useSelector(selectSubModule);

  const openLoginModal = useCallback(() => {
    dispatch(setLoginModal(true))
  }, [dispatch]);

  const openCart = useCallback(() => {
    // dispatch(setCartVisible(true))
    navigate(`/store/${storeName}/checkout`)
  }, [dispatch, storeName])

  const goToHome = useCallback(() => {
    navigate(`/store/${storeName}`)
  }, [navigate, storeName])

  const goToLink = useCallback((where) => {
    navigate(where)
  }, [navigate])

  const handleOpenUserMenu = useCallback(() => {
    dispatch(setMenuSliderToggle(true))
  }, [dispatch]);

  const legacyModule = ['statistics', 'course']

  const convertoToTitle = (module, subModule) => {
    if (module === 'store' || module === 'store_edit') return []
    const title = MODULES_LIST[module]?.label(t)
    return !subModule ? [title] : [title, ...subModule]
  }

  const { userInfo, loggedIn } = authState

  return (<>
      <ProfileAvatar onClick={handleOpenUserMenu} >
        {userInfo && <Avatar onClick={handleOpenUserMenu} url={userInfo?.user?.avatarUrl} name={userInfo?.user?.displayName} size='small' />}
      </ProfileAvatar>
     { !noHeader && <Header
        title={convertoToTitle(module, subModule)}
        headerLogo={userTheme?.logoUrl}
        cartItems={cartItemsCount}
        goToHome={goToHome}
        goToLink={goToLink}
        openCart={openCart}
        inStore={module === 'store'}
        ownerName={ownerName}
        openUserMenu={handleOpenUserMenu}
        loggedIn={loggedIn}
        openLoginModal={openLoginModal}
        userInfo={userInfo?.user}
        bgColor={module && legacyModule.includes(module) && theme.colors.COURSIO}
      /> }
  </>
  );
};

export default HeaderContainer;
