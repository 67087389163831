import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsBundle } from '../../hooks/readFromUrls';
import { useParams, useNavigate } from 'react-router-dom';

import { fetchCourse, selectLayoutId } from './courseSlice';
import Loading from '../../ui/Loading';
import { useTranslation } from 'react-i18next';
import { selectIsLoggedIn } from '../auth/authSlice';
import { selectStoreOwner } from './globalSlice';

const Purchase = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { courseSlug, name } = useParams();
  const dispatch = useDispatch();
  const isBundle = useIsBundle();
  const courseId = useSelector(selectLayoutId);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const storeOwner = useSelector(selectStoreOwner)

  useEffect(() => {
    if (!isLoggedIn && i18n.language !== storeOwner.locale) {
      i18n.changeLanguage(storeOwner.locale)
    }
  }, [storeOwner, i18n, isLoggedIn])

  useEffect(() => {
    dispatch(fetchCourse({ storeName: name, slugName: courseSlug, type: isBundle ? 2 : 1 }))
  }, []);

  useEffect(() => {
    if (courseId) {
      navigate(`/store/${name}/checkout?cart=[${courseId}]`);
    }
  }, [courseId]);

  return <Loading />;
};

export default Purchase;
