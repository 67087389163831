import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import { Typo, Loading } from '../../../../ui';
import { media } from '../../../../utils/media';
import { selectStoreOwner, selectStoreStatus } from '../../globalSlice';
import { addItemToCart, selectCartItemsIds, selectIsValidDiscount } from '../../cartSlice';
import { selectPaymentProcessData, selectPaymentStatus } from '../../paymentsSlice';
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED } from '../../../../utils/constants';
import PaymentProviderSnippet from './PaymentProviderSnippet';
import { selectItemsByArray } from '../../coursesSlice';
import { useSearchParams } from 'react-router-dom';
import CheckoutDataContainer from './CheckoutDataContainer';
import PaymentErrorMessage from './PaymentErrorMessage';
import { selectIsLoggedIn } from '../../../auth/authSlice';

const CheckoutWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0rem auto;

  padding: 2rem 0;
  ${media.lessThan('laptop')} {
    padding: 2rem 4rem;
  }

  h1{
    border-bottom: 1px solid ${(props) => props.theme.colors.NEUTRAL_20};
    padding-bottom: 1rem;
  }

`;

const Checkout = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const paymentProcessData = useSelector(selectPaymentProcessData);
  const paymentStatus = useSelector(selectPaymentStatus);
  const storeStatus = useSelector(selectStoreStatus)
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const storeOwner = useSelector(selectStoreOwner)

  const coursesInCart = searchParams.get('cart')
  const newCartItems = useSelector((state) => selectItemsByArray(state, JSON.parse(coursesInCart)))

  const cartItemsIds = useSelector(selectCartItemsIds)
  const updatedCartItems = useSelector((state) => selectItemsByArray(state, cartItemsIds))

  const isValidDiscount = useSelector(selectIsValidDiscount);

  const [hasPaymentProcessData, setHasPaymentProcessData] = useState(false);

  useEffect(() => {
    if (!isLoggedIn && i18n.language !== storeOwner.locale) {
      i18n.changeLanguage(storeOwner.locale)
    }
  }, [storeOwner, i18n, isLoggedIn])

  //READ FROM URL - DIRECTLY LANDING IN CHECKOUT WITH A CART
  useEffect(() => {
    if (newCartItems && storeStatus === STATUS_SUCCEEDED) {
      dispatch(addItemToCart(newCartItems))

      //after reading params remove them from url so we are able to manage cart without interference
      searchParams.delete('cart');
      setSearchParams(searchParams);
    }
  }, [newCartItems, dispatch, storeStatus, searchParams, setSearchParams])

  useEffect(() => {
    // if there is a discount active, store is updated and it is needed to updated cart
    // like this is not possible to have courses from different stores
    // BE should return cart values just by sending items ids and discount code 
    if (storeStatus === STATUS_SUCCEEDED)
      dispatch(addItemToCart(updatedCartItems))

  }, [isValidDiscount, storeStatus])

  useEffect(() => {
    setHasPaymentProcessData(paymentProcessData && Object.keys(paymentProcessData).length > 0)
  }, [setHasPaymentProcessData, paymentProcessData])

  return (
    <CheckoutWrapper>
      <Typo.PageHeader>{t('webshop.checkout.checkout')}</Typo.PageHeader>
      {paymentStatus === STATUS_LOADING ?
        <Loading />
        : paymentStatus === STATUS_FAILED ?
          <PaymentErrorMessage />
          : hasPaymentProcessData ?
            <PaymentProviderSnippet />
            : <CheckoutDataContainer />
      }
    </CheckoutWrapper>
  );
};

export default Checkout;

