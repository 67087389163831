import { createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { pageApi } from '../../../../utils/urls';
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCEEDED } from '../../../../utils/constants';
import httpClient from '../../../../services/httpClient';

// ----------------- Thunks -----------------------------

export const fetchPageById = createAsyncThunk('page/fetchPageById', async ({ pageId }, { getState, dispatch, rejectWithValue }) => {

  const body = {
    data: {
      id: pageId,
    },
    method: 'findById'
  };

  const res = await httpClient.post(pageApi(), body, getState, dispatch, rejectWithValue);

  return res.data;
});

// ----------------- Reducers ---------------------------

const initialState = {
  data: {}
};

const coursePageSlice = createSlice({
  name: 'coursePage',
  initialState,
  reducers: {

  },
  extraReducers: builder => {
    builder
      .addCase(fetchPageById.pending, (state, action) => {
        state.status = STATUS_LOADING;
      })
      .addCase(fetchPageById.rejected, (state, action) => {
        state.status = STATUS_FAILED;
      })
      .addCase(fetchPageById.fulfilled, (state, action) => {
        state.status = STATUS_SUCCEEDED;
        state.data = { ...state.data, ...action.payload };
      })
  }
})

export default coursePageSlice.reducer

// ----------------- Selectors --------------------------
export const selectPage = state => state.coursePage.data;
export const selectPageStatus = state => state.coursePage.status;

