import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import styled from 'styled-components';
import theme from "../../../ui/theme";
import { Flex, SubPage, Typo, IconAsButton, SearchSelect, Toggler, } from '../../../ui';
import { selectIsAdmin, selectMyStore } from '../../auth/authSlice';
import { breakpoints, CONTENT_MINI } from '../../../utils/media';
import { selectStoreHideVAT, updateCurrency, updateExVat } from '../settingsSlice';
import { selectCurrency } from '../../global/globalSlice';

const Store = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currency = useSelector(selectCurrency)
  const isAdmin = useSelector(selectIsAdmin)
  const storeName = useSelector(selectMyStore);
  const storeHideVat = useSelector(selectStoreHideVAT)
  const [editCurrencyView, setEditCurrencyView] = useState(false)
  const [selectedCurrency, setSelectedCurrency] = useState('SEK');
  const [withoutVAT, setWithoutVAT] = useState(false);

  useEffect(() => {
    setSelectedCurrency(currency);
  }, [currency]);

  useEffect(() => {
    setWithoutVAT(storeHideVat);
  }, [storeHideVat]);

  const saveCurrency = () => {
    setEditCurrencyView(false)
    dispatch(updateCurrency(selectedCurrency))
  }
  const fullScreen = window.innerWidth <= breakpoints.phablet;

  const handleTogglerClick = useCallback((checked) => {
    dispatch(updateExVat({ exVat: checked }))
    setWithoutVAT(checked)
  }, [withoutVAT, dispatch])

  return (
    <SubPage gap='50' size={CONTENT_MINI}>
      <Flex width='100%' gap='10' direction='column' align='flex-start'>
        <Typo.TextTitle>{t('settings.storeCurrency')}:</Typo.TextTitle>
        {!editCurrencyView ? <Flex gap='10'>
          <Typo.Text>{currency}</Typo.Text>
          <IconAsButton iconName='Edit' size='small' clickHandle={() => setEditCurrencyView(true)} />
        </Flex>
          : <Flex width='300px'>
            <SearchSelect
              id="currencies"
              options={['USD', 'SEK', 'NOK', 'EUR']}
              selectedOptions={selectedCurrency}
              // label={'currency'}
              setValue={(item) => setSelectedCurrency(item)}
            />
            <IconAsButton iconName='Cancel' color={theme.colors.ERROR_RED} clickHandle={() => setEditCurrencyView(false)} />
            <IconAsButton iconName='Valid' color={theme.colors.SUCCESS_GREEN} clickHandle={saveCurrency} />
          </Flex>

        }
      </Flex>
      {isAdmin && <>
        <Flex width='100%' gap='10' direction='column' align='flex-start' >
          <Typo.TextTitle>{t('settings.storeWithOrWithoutVAT')}:</Typo.TextTitle>
          <Toggler checked={withoutVAT} leftText={t('settings.storeWithVAT')} rightText={t('settings.storeWithoutVAT')} onClick={handleTogglerClick} />
        </Flex>
        <Flex width='100%' gap='10' direction='column' align='flex-start'>
          <Typo.TextTitle>{t('settings.storeURL')}:</Typo.TextTitle>
          <Typo.Text>{storeName}</Typo.Text>
        </Flex>
      </>}
    </SubPage >
  );
};

export default Store;
