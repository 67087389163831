import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Page, Button, Modal, Textinput, Flex } from '../../ui';
import { fetchMedia, selectMediaFolders, selectMediaBreadcrumbs, createDirectory, moveFile, selectMediaByType } from './mediaSlice';
import styled from 'styled-components';
import { selectIsIncarnated } from '../auth/authSlice';
import FoldersDirectory from './components/FoldersDirectory';
import FilesSection from './components/FilesSection';
import { CONTENT_WIDE } from '../../utils/media';
import { fileUpload, selectFileStatus } from '../fileUploader/fileSlice';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import FileDetails from './components/FileDetails';
import MediaBreadCrumbs from './components/MediaBreadcrumbs';
import { useTranslation } from 'react-i18next';
import Loading from '../../ui/Loading';
import PageHeader from '../../ui/Page/PageHeader';
import PageControls from '../../ui/Page/PageControls';

const Container = styled.div`
  display: flex;
  flex-direction:column;
  gap:40px;
  width: 100%;
  align-content:flex-start;
`;

const MediaLibrary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { folderId, fileId } = useParams();
  const breadcrumbs = useSelector(selectMediaBreadcrumbs);
  const isIncarnated = useSelector(selectIsIncarnated);
  const fileStatus = useSelector(selectFileStatus);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('image')
  const [query, setQuery] = useState('')
  const foldersChildren = useSelector((state) => selectMediaFolders(state, { query }));
  const files = useSelector(state => selectMediaByType(state, { type: selectedFilter, query }));

  const [modal, setModal] = useState(false);
  const [folderName, setFolderName] = useState('');

  useEffect(() => {
    setSelectedFolder(folderId || '0')
  }, [folderId])

  useEffect(() => {
    selectedFolder && dispatch(fetchMedia({ directoryId: selectedFolder }));
  }, [dispatch, isIncarnated, selectedFolder])

  const handleOpenFolder = (obj) => {
    setQuery('')
    navigate(`/media-library${obj?.id ? '/' + obj.id : ''}`)
  }

  const handleModal = useCallback(() => {
    setModal(!modal);
    setFolderName('')
  }, [setModal, modal]);

  const handleFolderName = useCallback((e) => {
    setFolderName(e.target.value)
  }, [setFolderName]);

  const createFolder = useCallback(() => {
    dispatch(createDirectory({ directoryId: folderId, name: folderName }))
    setModal(false);
  }, [dispatch, folderId, folderName, setModal]);

  const uploadFile = useCallback((e) => {
    dispatch(fileUpload(e.target.files[0])).then((res) => {
      try {
        dispatch(moveFile({ file: res.payload[0].file, id: res.payload[0].file.id, directoryId: selectedFolder, isMessage: false }))
      } catch { }
    });
  }, [dispatch, selectedFolder])

  const chooseFile = useCallback(() => {
    document.getElementById('mediaLibraryUpload').value = null;
    document.getElementById('mediaLibraryUpload').click();
  }, []);

  const clearSearch = useCallback(() => {
    setQuery('')
  }, [setQuery]);

  const handleSearch = useCallback((value) => {
    setQuery(value)
  }, []);

  // MISSING TO DO:
  // improve performance
  // preview of "other" files in modal details - pdf, doc, etc
  // delete folders - not possible until BE allows to delete files

  return (
    <Page noScroll={true} size={CONTENT_WIDE} gap='30'
      staticElements={
        <PageHeader>
          <PageControls
            onClearSearch={clearSearch}
            onSearch={handleSearch}
            placeholder={t('mediaLibrary.searchPlaceholder')}
            openModelButton={
              <Flex gap='10'>
                <Button icon='CreateFolder' onClick={handleModal}>{t('mediaLibrary.createFolders')}</Button>
                <Button icon='Upload' onClick={chooseFile}>{t('mediaLibrary.uploadFiles')}</Button>
                <input id={'mediaLibraryUpload'} accept="*" style={{ display: 'none' }} type="file" onChange={uploadFile} />
              </Flex>
            }
          >
            <MediaBreadCrumbs onOpenFolder={handleOpenFolder} breadcrumbs={breadcrumbs} />
          </PageControls>
        </PageHeader>
      }
    >
      {fileStatus === 'fileLoading' && <Loading type="page" />}
      <DndProvider backend={HTML5Backend}>
        <Container>
          <FoldersDirectory selectedFolder={selectedFolder} data={foldersChildren} openFolder={handleOpenFolder} withDND={true} />
          <FilesSection withDND={true} currentDirectory={selectedFolder} data={files} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />
        </Container>
      </DndProvider>
      <FileDetails fileId={fileId} />
      <Modal
        open={modal}
        onClose={handleModal}
        title={t('mediaLibrary.newFolder')}
        acceptLabel={t('globals.create')}
        onAccept={createFolder}
        onCancel={handleModal}
        showActions
      >
        <Textinput
          label={t('globals.name')}
          propName={'Name'}
          defaultValue={folderName}
          onBlurValue={handleFolderName}
        />
      </Modal>
    </Page>
  );
};

export default MediaLibrary;
