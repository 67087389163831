import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Flex, Textinput, Typo, Button, SearchSelect } from '../../ui';
import { media } from '../../utils/media';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../utils/constants';
import ShowTermsAndConditions from '../../features/webshop/components/ShowTermsAndConditions';

const Wrapper = styled.div`  
  background-color: white;
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const InputsWrapper = styled.div`
  display: flex;
  gap: 30px;
  width:100%;
  ${media.lessThan('phablet')} {
    flex-direction:column;
    gap:5px;
  }
`

const RegisterButton = styled(Button)`
  align-self:flex-end;
`

const RegistrationForm = ({ handleLogin, email, createAccountCall, callbackFn }) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const registrationRef = useRef({});

  const [editedLanguage, setEditedLanguage] = useState({ value: 'en_US', label: 'English' });

  useEffect(() => {
    const currentLanguage = LANGUAGES.find((obj) => obj.value === i18n.language)
    setEditedLanguage(currentLanguage)
  }, [i18n.language]);

  const editLanguageSetting = useCallback((language) => {
    if (language.value !== i18n.language) {
      i18n.changeLanguage(language.value)
    }
    setEditedLanguage(language)

  }, [i18n])

  const createAccount = useCallback(() => {
    const { password, firstName, lastName, inputEmail } = registrationRef.current;
    const regInfo = {
      email: email && email !== 'Not provided' ? email : inputEmail.value,
      password: password.value,
      firstName: firstName.value,
      lastName: lastName.value,
      locale: editedLanguage.value
    }
    dispatch(createAccountCall(regInfo)).then(() => {
      if (typeof callbackFn === 'function') {
        callbackFn();
      }
    });
  }, [dispatch, editedLanguage, email])

  return (
    <Wrapper>
      <div>
        <Typo.TextTitle>{t('registration.membership')}</Typo.TextTitle>
        <Typo.MediumText>{t('registration.accountRequired')}</Typo.MediumText>
      </div>
      {email && email !== 'Not provided' && <div>
        <Typo.TextTitle>{t('registration.accountEmail')}</Typo.TextTitle>
        <Typo.MediumText>{email}</Typo.MediumText>
      </div>}
      <Flex direction='column' width='100%' >
        <InputsWrapper >
          <Textinput
            autoFocus
            label={t('profile.firstName')}
            propName={'firstName'}
            inputRef={ref => registrationRef.current['firstName'] = ref}
          />
          <Textinput
            autoFocus
            label={t('profile.lastName')}
            propName={'lastName'}
            inputRef={ref => registrationRef.current['lastName'] = ref}
          />
        </InputsWrapper>
        <InputsWrapper>
          {(!email || email === 'Not provided') && <Textinput
            autoFocus
            label={t('globals.email')}
            propName={'email'}
            inputRef={ref => registrationRef.current['inputEmail'] = ref}
          />}
          <Textinput
            autoFocus
            type='password'
            label={t('globals.password')}
            propName={'password'}
            inputRef={ref => registrationRef.current['password'] = ref}
          />

        </InputsWrapper>
        <SearchSelect
          setValue={editLanguageSetting}
          selectedOptions={editedLanguage}
          id='language'
          options={LANGUAGES}
          label={t('profile.language')}
          optionLabel='label'
        />
        <Flex direction='column'>
          <Typo.Info>{t('registration.acceptTermsByRegistering')}</Typo.Info>
          <ShowTermsAndConditions />
        </Flex>
      </Flex>
      <RegisterButton onClick={createAccount}>{t('registration.register')}</RegisterButton>
      <Typo.SmallText>{t('registration.ifAccExists')} <Button $asText onClick={handleLogin}>{t('registration.loginHere')}</Button></Typo.SmallText>

    </Wrapper>
  )
}

export default RegistrationForm;

