import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { SearchBar, ListTable, Flex, Typo, Button, Modal, Textinput, SubPage } from '../../../ui';
import { selectIsIncarnated } from '../../auth/authSlice';
import Group from './Group';
import { fetchEditableGroups, selectGroups, selectStatus, deleteGroup, createGroup, selectCreateStatus, selectNewlyCreated } from '../groupsSlice';
import Loading from '../../../ui/Loading';
import { STATUS_LOADING, STATUS_SUCCEEDED } from '../../../utils/constants';
import ConfirmDelete from '../../../ui/Modal/ConfirmDelete';
import { useTranslation } from 'react-i18next';
import { media } from '../../../utils/media';
import PageHeader from '../../../ui/Page/PageHeader';
import PageControls from '../../../ui/Page/PageControls';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${media.lessThan('phablet')} {
    flex-direction:column;
    gap:20px;
  }
`;

const Controls = styled.div`
  width: 800px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2rem;
  ${media.lessThan('phablet')} {
    flex-direction:column;
    align-items: flex-end;
    margin-bottom: 0;
    width: 100%;
  }
`;

const SearchWrapper = styled.div`
  max-width: 450px;
  width: 100%;
`;

const GroupsList = ({ modal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const groups = useSelector(selectGroups);
  const isIncarnated = useSelector(selectIsIncarnated);
  const loadStatus = useSelector(selectStatus);
  const newlyCreated = useSelector(selectNewlyCreated);
  const createStatus = useSelector(selectCreateStatus);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortMode, setSortMode] = useState('asc');
  const [isCreateGroup, setIsCreateGroup] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const [filteredGroups, setFilteredGroups] = useState(null);
  const groupNameRef = useRef();

  // const [length, setLength] = useState(25)
  const length = 25
  const navigate = useNavigate();

  const openGroup = useCallback((obj) => {
    navigate(`/users/groups/${obj.id}`)
  }, [navigate]);

  useEffect(() => {
    const newGroups = groups.filter(group => group.name.toLowerCase().includes(searchQuery))
    newGroups && setFilteredGroups(newGroups)
  }, [groups, searchQuery])

  const handleSearch = useCallback((value) => {
    setSearchQuery(value)
  }, []);

  const clearSearch = useCallback(() => {
    setSearchQuery('');
  }, [setSearchQuery]);

  const handleSort = useCallback((val) => {
    setSortBy(val);
    if (sortBy === val) {
      setSortMode('desc')
    }
    if (sortMode === 'desc') {
      setSortMode('asc')
    }
  }, [setSortBy, sortBy, setSortMode, sortMode]);

  //Add 25 more items to api call
  // const loadMore = useCallback(() => {
  //   setLength((prevLength) => prevLength + 25)
  // }, [setLength]);

  useEffect(() => {
    dispatch(fetchEditableGroups({ pageLength: length, sortBy: sortBy, sortMode: sortMode }));
  }, [dispatch, isIncarnated, sortBy, sortMode, length])

  useEffect(() => {
    if (createStatus === STATUS_SUCCEEDED && isCreateGroup) {
      openGroup(newlyCreated)
      setIsCreateGroup(false)
    }
  }, [createStatus, newlyCreated, openGroup, isCreateGroup])

  const deleteGroupConfirmed = () => {
    dispatch(deleteGroup(groupToDelete.id)).then(() => setGroupToDelete(null))
  }

  const tableInfo = [
    { label: t('globals.name'), handleClick: () => handleSort('name'), data: (item) => item.name },
    { label: t('globals.created'), data: (item) => item.created },
    { label: t('globals.courses'), data: (item) => item.courseCount },
    { label: t('globals.users'), data: (item) => item.studentCount },
    // { label: 'invitations', data: (item) => item.invitationCount },
    {
      label: '', menuOptions: () => [
        { label: t('groups.groupDetails'), onClick: openGroup },
        { label: t('groups.deleteGroup'), onClick: (item) => setGroupToDelete(item) },
      ]
    },
  ]

  const createGroupHandler = () => {
    setIsCreateGroup(true)
  }

  const saveGroup = () => {
    dispatch(createGroup(groupNameRef.current.value))
  }

  return (
    <SubPage staticElements={
      <PageHeader>
        <PageControls
          onClearSearch={clearSearch}
          onSearch={handleSearch}
          placeholder={t('groups.searchPlaceholder')}
          disableClear={!searchQuery}
          openModelButton={
            <Button icon='Edit' onClick={createGroupHandler}>{t('groups.createGroup')}</Button>
          }
        />
      </PageHeader>
    }>
      {loadStatus === STATUS_LOADING ? <Loading /> :
        <ListTable
          tableInfo={tableInfo}
          data={filteredGroups}
          onRowClick={openGroup}
          tableName={'groups'}
        />}
      <Group open={!!modal} />
      <ConfirmDelete
        onClose={setGroupToDelete}
        onAcceptDelete={deleteGroupConfirmed}
        open={!!groupToDelete?.id}
        description={t('groups.confirmDelete', { groupToDelete: groupToDelete?.name })}
      />
      <Modal open={isCreateGroup}
        onClose={() => setIsCreateGroup(false)}
        showActions
        onAccept={saveGroup} title={t('groups.createGroup')}
      >
        {createStatus === STATUS_LOADING ?
          <Loading /> :
          <Textinput
            placeholder={t('course.courseName')}
            innerRef={groupNameRef}
          />}
      </Modal>
    </SubPage >
  );
};

export default GroupsList;
