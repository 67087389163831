import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import { SearchBar } from '../../ui';
import { media } from '../../utils/media';

const ControlsWrapper = styled.div`
  display: flex;
  flex-direction:column;
  align-items: flex-start;
  width: 100%;
  gap: 1.5rem;
`;

const Controls = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;

  ${media.lessThan('phablet')} {
    flex-direction:column;
    align-items: flex-end;
  }
`;

const SearchWrapper = styled.div`
  max-width: 450px;
  width: 100%;
`;


const PageControls = ({ onClearSearch, onSearch, placeholder, disableClear, openModelButton, children }) => {

  const searchRef = useRef();

  const handleSearch = debounce((e) => {
    const value = e.target.value.toLowerCase()
    onSearch(value);
  }, 1000);

  const clearSearch = useCallback(() => {
    searchRef.current.value = '';
    onClearSearch();
  }, []);

  return (
    !children ?
      <Controls>
        <SearchWrapper>
          <SearchBar placeholder={placeholder} handleSearch={handleSearch} handleClear={clearSearch} disableClear={disableClear} inputRef={searchRef} />
        </SearchWrapper>
        {openModelButton}
      </Controls> :
      <ControlsWrapper>
        <Controls>
          <SearchWrapper>
            <SearchBar placeholder={placeholder} handleSearch={handleSearch} handleClear={clearSearch} disableClear={disableClear} inputRef={searchRef} />
          </SearchWrapper>
          {openModelButton}
        </Controls>
        {children}
      </ControlsWrapper>
  );
};

export default PageControls;
