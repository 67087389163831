import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, List, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import { Button, Flex, Loading, SubPage, Typo } from '../../../ui';
import { selectFeatures, updateSettings, setFeatures, selectFetchStatus, ADMIN_ONLY_FEATURES, FEATURE_FLAGS } from '../settingsSlice';
import { STATUS_LOADING } from '../../../utils/constants';
import { selectIsAdmin, selectIsIncarnated } from '../../auth/authSlice';
import { media } from '../../../utils/media';

const FeaturesWrapper = styled.div`
  flex-direction: row;
  gap: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  
  ${media.lessThan('tablet')} {
    flex-direction: column;
  }
`

const Features = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const features = useSelector(selectFeatures);
  const fetchStatus = useSelector(selectFetchStatus);

  const isIncarnated = useSelector(selectIsIncarnated);
  const isAdmin = useSelector(selectIsAdmin);

  const [localFeatures, setLocalFeatures] = useState();
  const [featureFlagsItems, setFeatureFlagsItems] = useState({});
  const [toShowAlways, setToShowAlways] = useState({});

  useEffect(() => {
    setLocalFeatures(features)
  }, [features])

  useEffect(() => {

    const flags = {}
    const others = {}

    for (const key in localFeatures) {
      if (ADMIN_ONLY_FEATURES.includes(key) && !(isIncarnated || isAdmin)) { }
      else if (FEATURE_FLAGS.includes(key)) flags[key] = localFeatures[key]
      else others[key] = localFeatures[key]

    }

    setFeatureFlagsItems(flags)
    setToShowAlways(others)

  }, [localFeatures])

  const handleToggle = (key) => {
    const newChecked = { ...localFeatures }
    newChecked[key] = !newChecked[key]
    setLocalFeatures(newChecked)
  };

  const handleSave = () => {
    const newFeatures = { ...toShowAlways, ...featureFlagsItems }
    dispatch(updateSettings({ settingsData: { features: newFeatures } }));
    dispatch(setFeatures(newFeatures))
  }

  return fetchStatus === STATUS_LOADING ? <Loading /> :
    <SubPage gap='20' align='flex-start' >
      <FeaturesWrapper gap='20' align='flex-start' >
        <Flex gap='10' direction='column' align='flex-start'>
          <Typo.TextTitle>{t('settings.feature_title')}</Typo.TextTitle>
          <Typo.MediumText>{t('settings.feature_description')}</Typo.MediumText>
          <List sx={{ width: '100%', maxWidth: 360 }}>
            {toShowAlways && Object.keys(toShowAlways).map((key) => {
              const labelId = `checkbox-list-label-${key}`;
              return (
                <ListItem
                  key={key}
                  disablePadding
                >
                  <ListItemButton role={undefined} onClick={() => handleToggle(key)} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={localFeatures[key]}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <Typo.Text>{t(`settings.${key}`)}</Typo.Text>
                  </ListItemButton>
                </ListItem>
              )
            })}
          </List>
        </Flex>
        {!!Object.keys(featureFlagsItems).length && <Flex gap='10' direction='column' align='flex-start'>
          <Typo.TextTitle>{t('settings.new_feature_flags_title')}</Typo.TextTitle>
          <Typo.MediumText>{t('settings.new_feature_flags_description')}</Typo.MediumText>
          <List sx={{ width: '100%', maxWidth: 360 }}>
            {Object.keys(featureFlagsItems).map((key) => {
              const labelId = `checkbox-list-label-${key}`;
              return (
                <ListItem
                  key={key}
                  disablePadding
                >
                  <ListItemButton role={undefined} onClick={() => handleToggle(key)} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={localFeatures[key]}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <Typo.Text>{t(`settings.${key}`)}</Typo.Text>
                  </ListItemButton>
                </ListItem>
              )
            })}
          </List>
        </Flex>
        }
      </FeaturesWrapper>
      {<Flex direction='row' gap='10' width='100%' justify='flex-end' >
        {/* <Button onClick={handleCancel}>{t('globals.cancel')}</Button> */}
        <Button onClick={handleSave}>{t('globals.save')}</Button>
      </Flex>}
    </SubPage>
};

export default Features;
